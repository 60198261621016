
import React, { useState } from 'react';
import { render } from 'react-dom';
import { App } from './App';

import log from 'loglevel';

import { Auth0Provider } from "@auth0/auth0-react";

log.setLevel ( "info" );

function TopLevel ( props )
{
	return (

		<Auth0Provider
			domain="dev-wdra7zfm.us.auth0.com"
			clientId="9F7yi1SagAKpWHeZ1JYBqA9SKZIkr2rF"
			authorizationParams={{
				redirect_uri: window.location.origin,
				audience: "https://services.expopkg.rathravane.systems"
			}}
			cacheLocation="localstorage"
		>
			<App />
		</Auth0Provider>
	);
}

render (
	<TopLevel />,
	document.getElementById('root')
);
